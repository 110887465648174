import { ForwardedRef } from "react";

import { NotFoundError } from "@bps/http-client";
import { to2dp } from "@bps/utils";
import { AddressDto } from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";

export const getOrUndefined = <TValue>(
  map: Map<string, TValue>,
  key: string | undefined
) => (key ? map.get(key) : undefined);

export const wildCardCheck = (value: string) =>
  (value.includes("*") ? value : `${value}*`).replace(/ +/g, "*");

/**
 * Return the value from a map given a key or add the result of addFn to the map and return it.
 * @param map
 * @param key
 * @param addFn
 */
export const getOrAdd = <K, V>(map: Map<K, V>, key: K, addFn: () => V): V => {
  if (!map.has(key)) {
    const value = addFn();
    map.set(key, value);
    return value;
  }

  return map.get(key)!;
};

export function hasAny<T>(
  items: T[],
  predicate: (item: T) => boolean
): boolean {
  return items.findIndex(predicate) !== -1;
}

export function capitalizeSentence(
  value: string,
  options?: { allWords: boolean }
) {
  if (options?.allWords) {
    return value
      .split(" ")
      .map(str => str.substring(0, 1).toUpperCase() + str.slice(1))
      .join(" ");
  }

  // Note that this lowercases the non-uppercase letters while the allWords
  //  option does not.  Please update if required.
  return value.substring(0, 1).toUpperCase() + value.slice(1).toLowerCase();
}

export function pluralizeString(value: string, suffix = "s") {
  if (value.charAt(value.length - 1) === suffix) {
    return value;
  }

  // Special case for words like "Summary", "Try", etc...
  if (value.endsWith("ry")) {
    const strippedString = value.substring(0, value.length - 2);
    return `${strippedString}ries`;
  }

  return `${value}${suffix}`;
}
export function pluralizeAndCapitalizeString(value: string, suffix = "s") {
  return capitalizeSentence(pluralizeString(value, suffix));
}

/**Utility function to get a value given a key or to throw when the value cannot be found */
export function getOrThrow<K, V>(
  map: Map<K, V>,
  key: K,
  error?: string | Error
): V {
  const value = map.get(key);
  if (value === undefined) {
    throw error || new Error(`item ${key} not found`);
  }
  return value;
}

export function computePersonInitials(
  firstName: string | undefined,
  lastName?: string | undefined
): string {
  return `${firstName?.[0] ?? ""}${lastName?.[0] ?? ""}`.toUpperCase();
}

export const contains = (input: string, search: string) =>
  input != null && input.toLowerCase().indexOf(search.toLowerCase()) > -1;

export const boolToYesNo = (value?: boolean) => (value ? "Yes" : "No");

export const isAllUndefined = (values: any[]) => {
  return values.every(x => !x);
};

export const identity = (x: any) => x;

/**
 * Sort method which does a sort on two strings, doing a numeric sort first on
 * the initial number substring, before sorting the rest alphabetically
 * @param a string
 * @param b string
 * @returns number
 */
export const compareNumberStrings = (a: string, b: string): number => {
  if (a === b) return 0;

  const firstNonNumberPositionA = a.search("[^0-9]");

  const numA =
    firstNonNumberPositionA === -1
      ? a
      : a.substring(0, firstNonNumberPositionA);

  const firstNonNumberPositionB = b.search("[^0-9]");

  const numB =
    firstNonNumberPositionB === -1
      ? b
      : b.substring(0, firstNonNumberPositionB);

  if (numA !== numB) {
    if (!numA) return 1;
    if (!numB) return -1;
    return Number(numA) - Number(numB);
  }

  const strA =
    firstNonNumberPositionA === -1 ? "" : a.substring(firstNonNumberPositionA);

  const strB =
    firstNonNumberPositionB === -1 ? "" : b.substring(firstNonNumberPositionB);

  return strA > strB ? 1 : -1;
};

export const sortByKey = <T>(
  items: T[],
  columnKey: keyof T,
  isSortedDescending?: boolean
): T[] => {
  const key = columnKey;
  return items
    .slice(0)
    .sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
};

export const sum = <T extends {}>(key: keyof T, itemsToSum: T[]) => {
  return to2dp(
    itemsToSum.reduce((total, item) => total + Number(item[key] ?? 0), 0)
  );
};

export const getValueWithUnits = (
  value: number,
  unit: string,
  plural = `${unit}s`
) => {
  return value === 1 ? `${value} ${unit}` : `${value} ${plural}`;
};

export const catchNotFoundError = (error: Error) => {
  if (error instanceof NotFoundError) {
    return undefined;
  }

  throw error;
};

export const mergeModels = <
  TModel extends {
    id: string;
  }
>(
  source: TModel[],
  arrayToMerge: TModel[],
  appendToStart = false
) => {
  arrayToMerge.forEach(mergeModel => {
    const index = source.findIndex(origModel => origModel.id === mergeModel.id);
    if (index === -1) {
      appendToStart ? source.unshift(mergeModel) : source.push(mergeModel);
    }
  });
};
export const mergePaginationResults = <TModel extends { id: string }>(
  currentResult: QueryResult<TModel>,
  resultToMerge: QueryResult<TModel>
) => {
  const { results, ...queryResultRest } = resultToMerge;

  mergeModels(currentResult.results, results);

  currentResult.skip = queryResultRest.skip;
  currentResult.total = queryResultRest.total;
  currentResult.take = queryResultRest.take;

  return currentResult;
};

export function findAddressValue(
  array: AddressDto[] | undefined,
  type: string
) {
  const address = array && array.find(x => x.type === type);
  return address && address;
}

/**
 * Replaces any undefined prop to be null
 * This will mutate the source object.
 * Use case is for treating undefined prop as null when generating
 * a JSON PATCH request.
 * @param source
 */
export function nullifyAnyUndefined<T extends {}>(source: T): Patch<T> {
  Object.keys(source).forEach(key => {
    const value = source[key];
    if (value === undefined) {
      source[key] = null;
    } else if (typeof value === "object" && !Array.isArray(value)) {
      nullifyAnyUndefined(value);
    }
  });
  return source as Patch<T>;
}

/**
 * replaces any null property value to undefined in a recursive manner.
 * @param object
 */
export function withNullToUndefined<T extends object>(
  object: T
): WithoutNullDeep<T> {
  const copy = JSON.parse(JSON.stringify(object));
  Object.keys(copy).forEach(key => {
    const val = copy[key];
    if (val === null) {
      copy[key] = undefined;
    } else if (typeof val === "object") {
      copy[key] = withNullToUndefined(copy[key]);
    }
  });
  return copy;
}

export const setForwardedRef = <T>(ref: ForwardedRef<T>, instance: T) => {
  if (ref) {
    if (typeof ref === "function") {
      ref(instance);
    } else if (typeof ref === "object") {
      ref.current = instance;
    }
  }
};
