import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import {
  Baggage,
  UIOptionBaggage
} from "@libs/analytics/app-insights/app-insights.types.ts";
import { IF_MATCH_HEADER } from "@libs/api/api.constants.ts";
import { excludedRefDataFields, RefDataDto } from "@libs/api/ref-data/dto.ts";
import { setCorrelationBaggageHeader } from "@libs/api/utils/correlation-baggage-header.ts";
import {
  toQueryResult,
  withoutFields,
  withPaging
} from "@libs/api/utils/paging.utils.ts";
import {
  AddAppointmentCancellationReasonDto,
  AddAppointmentConfirmationCampaignDto,
  AddAppointmentEncountersDto,
  AddAppointmentReminderJobDto,
  AddAppointmentTypeDto,
  AddBookingTenantSettingsDto,
  AddCalendarEventDto,
  AddCalendarEventReminderDto,
  AddProviderCommentDto,
  AddRecurrenceDto,
  AddWaitingListItemDto,
  AppointmentCancellationReasonDto,
  AppointmentConfirmationCampaignDto,
  AppointmentEncountersDto,
  AppointmentReminderJobDto,
  AppointmentReminderJobPreviewArgs,
  AppointmentReminderJobPreviewDto,
  AppointmentReminderJobRunArgsDto,
  AppointmentReminderJobRunSummaryDto,
  AppointmentReminderJobScheduleBehavior,
  AppointmentStatusDto,
  AppointmentTypeDto,
  AvailabilitySlotResponseDto,
  BookingTenantSettingsDto,
  CalendarEventAppointmentStatusChangeDto,
  CalendarEventConfirmationDto,
  CalendarEventDto,
  CalendarEventFormInstanceDTO,
  CalendarEventReminderDto,
  CalendarEventReminderMessageDto,
  CalendarEventReminderReplyDto,
  CalendarEventReminderSearchArgs,
  CalendarEventReminderSearchDto,
  CalendarEventReminderStatus,
  CalendarEventTypeRefDto,
  CalendarSeriesDto,
  CancelCalendarSeriesDto,
  EventReminderDto,
  GetAppointmentCancellationReasonDto,
  GetAppointmentEncountersRequest,
  GetAppointmentTypesDto,
  GetAvailabilitySlotDto,
  GetCalendarEventsDto,
  GetOrgUnitAvailableTimeRangeDto,
  GetRecurringAppointmentConflictDto,
  GetUserTimeRangeDto,
  GetWaitingListDto,
  OrgUnitAvailabilityDto,
  PatchAppointmentConfirmationCampaignDto,
  PatchAppointmentReminderJobDto,
  PatchAppointmentTypeDto,
  PatchBookingTenantSettingsDto,
  PatchCalendarEventDto,
  PatchOrgUnitAvailabilityDto,
  PatchRecurrenceDto,
  PatchUserAvailabilityDto,
  PatchUserUnavailabilityDto,
  RecurrenceDto,
  RecurringAppointmentHelperTextDto,
  SingleCalendarEventReminderDto,
  TimeRangeDto,
  UpdateAppointmentCancellationReasonDto,
  UpdateCalendarEventReminderDto,
  UpdateWaitingListItemDto,
  UserAvailabilityDto,
  UserAvailabilityRequestDto,
  UserTimeRangeDto,
  UserUnavailabilityDto,
  ValidateCalendarEventResponse,
  ValidateRecurrenceSeriesConflictDto,
  WaitingListItemDto
} from "@libs/gateways/booking/BookingGateway.dtos.ts";
import { IBookingGateway } from "@libs/gateways/booking/BookingGateway.interface.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";

const waitingListItemsExcludeFields = [nameof<WaitingListItemDto>("changeLog")];

export class BookingGateway implements IBookingGateway {
  constructor(private api: AxiosInstance) {}

  async cancelCalendarSeries(
    id: string,
    request: CancelCalendarSeriesDto
  ): Promise<void> {
    await this.api.post<void>(`/calendarseries/cancel/${id}`, request);
  }

  getAvailabilitySlot(
    request: GetAvailabilitySlotDto
  ): Promise<AvailabilitySlotResponseDto[]> {
    const queryString = withPaging({
      orgUnitIds: request.orgUnitIds,
      startDate: request.startDate,
      endDate: request.endDate,
      duration: request.duration,
      excludeCalendarEvent: request.excludeCalendarEvent
    });
    return this.api
      .get<AvailabilitySlotResponseDto[]>(
        `user/${request.userId}/availabilityslots?${stringify(queryString)}`
      )
      .then(x => x.data);
  }

  getUserTimeRange(request: GetUserTimeRangeDto): Promise<UserTimeRangeDto> {
    const queryString = withPaging({
      dateTo: request.dateTimeTo,
      dateFrom: request.dateTimeFrom,
      isStandardHours: request.isStandardHours,
      userIds: request.userIds,
      orgUnitId: request.orgUnitId
    });

    const urlQueryString = `users/timeRange?${stringify(queryString)}`;

    return this.api.get<UserTimeRangeDto>(urlQueryString).then(x => x.data);
  }

  getOrgUnitAvailableTimeRange(
    request: GetOrgUnitAvailableTimeRangeDto
  ): Promise<TimeRangeDto[]> {
    const queryString = withPaging({
      dateTo: request.dateTimeTo,
      dateFrom: request.dateTimeFrom
    });

    const urlQueryString = `orgUnit/${request.orgUnitId}/timeRange?${stringify(
      queryString
    )}`;

    return this.api.get<TimeRangeDto[]>(urlQueryString).then(x => x.data);
  }

  getRecurrence(id: string): Promise<RecurrenceDto> {
    return this.api
      .get<RecurrenceDto>(`/calendareventrecurrence/${id}`)
      .then(x => x.data);
  }

  getCalendarSeries(id: string): Promise<CalendarSeriesDto> {
    return this.api
      .get<CalendarSeriesDto>(`/calendarSeries/${id}`)
      .then(x => x.data);
  }

  async getWaitingListItems(
    request: GetWaitingListDto
  ): Promise<WaitingListItemDto[]> {
    const queryString = withoutFields(waitingListItemsExcludeFields, request);

    const response = await this.api
      .get<WaitingListItemDto[]>(
        `/calendarevents/waitingList?${stringify(queryString)}`
      )
      .then(x => x.data);

    return response;
  }

  amendCalendarSeries(
    id: string,
    recurrence?: AddRecurrenceDto
  ): Promise<RecurrenceDto> {
    return this.api
      .post<RecurrenceDto>(
        `/calendarSeries/fromCalendarEvent/${id}`,
        recurrence
      )
      .then(x => x.data);
  }

  addRecurrence(data: AddRecurrenceDto): Promise<RecurrenceDto> {
    return this.api
      .post<RecurrenceDto>("/calendareventrecurrence", data)
      .then(x => x.data);
  }

  updateRecurrence(data: PatchRecurrenceDto): Promise<RecurrenceDto> {
    return this.api
      .patch<RecurrenceDto>(`/calendareventrecurrence/${data.id}`, data, {
        headers: {
          [IF_MATCH_HEADER]: data.eTag
        }
      })
      .then(x => x.data);
  }

  async deleteRecurrence(id: string) {
    await this.api.delete(`/calendareventrecurrence/${id}`);
  }

  getRecurringAppointmentHelperText(
    userId: string,
    request: GetRecurringAppointmentConflictDto
  ): Promise<RecurringAppointmentHelperTextDto> {
    const response = this.api
      .get<RecurringAppointmentHelperTextDto>(
        `/user/${userId}/RecurringAppointmentHelperText?${stringify(request)}`
      )
      .then(x => x.data);
    return response;
  }

  addCalendarEvent(
    data: AddCalendarEventDto,
    baggage?: Baggage
  ): Promise<CalendarEventDto> {
    return this.api
      .post<CalendarEventDto>(
        "/calendarevents",
        data,
        setCorrelationBaggageHeader({ baggage })
      )
      .then(x => x.data);
  }

  async updateCalendarEvent(
    data: PatchCalendarEventDto,
    baggage?: Baggage
  ): Promise<CalendarEventDto> {
    const result = await this.api
      .patch<CalendarEventDto>(
        `/calendarevents/${data.id}`,
        data,
        setCorrelationBaggageHeader({ eTag: data.eTag, baggage })
      )
      .then(x => x.data);
    return result;
  }

  validateCalendarEvent(
    params?: ValidateRecurrenceSeriesConflictDto
  ): Promise<ValidateCalendarEventResponse> {
    return this.api
      .get<ValidateCalendarEventResponse>(
        "/calendarevents/validateCalendarEvent",
        {
          params
        }
      )
      .then(x => x.data);
  }
  getCalendarEvent(id: string): Promise<CalendarEventDto> {
    return this.api
      .get<CalendarEventDto>(`/calendarevents/${id}`)
      .then(x => x.data);
  }

  getWaitingListItem(id: string): Promise<WaitingListItemDto> {
    return this.api
      .get<WaitingListItemDto>(`/calendarevents/waitingList/${id}`)
      .then(x => x.data);
  }

  getCalendarEvents(
    request: GetCalendarEventsDto,
    baggage?: UIOptionBaggage
  ): Promise<QueryResult<CalendarEventDto>> {
    const queryString = withPaging(request);
    return this.api
      .get<CalendarEventDto[]>(
        `/calendarevents?${stringify(queryString)}`,
        setCorrelationBaggageHeader({ baggage })
      )
      .then(toQueryResult);
  }

  // This function return true when Appointment calender event which is getting modified is < 24 hrs and false when > 24
  getCalendarEventAppointmentStatusChangeDateTimeLimit(
    id: string
  ): Promise<boolean> {
    return this.api
      .get<boolean>(`/calendarevents/${id}/statusChangeDateTimeLimit`)
      .then(x => x.data);
  }

  updateCalendarEventAppointmentStatusChange(
    id: string,
    request: CalendarEventAppointmentStatusChangeDto
  ): Promise<void> {
    const x = this.api.post<void>(
      `/calendarevents/${id}/statusChange`,
      request
    );
    return x.then(x => x.data);
  }

  updateCalendarEventAttendeeStatusChange(
    attendeeId: string,
    request: CalendarEventAppointmentStatusChangeDto
  ): Promise<void> {
    return this.api.post(
      `/calendarevents/${request.calendarEventId}/statusChange/${attendeeId}`,
      request
    );
  }

  addTemporaryReservation(
    data: AddCalendarEventDto
  ): Promise<CalendarEventDto> {
    return this.api
      .post<CalendarEventDto>("/calendarevents/tempReservation", data)
      .then(x => x.data);
  }

  async deleteTemporaryReservation(): Promise<void> {
    return await this.api.delete("/calendarevents/deleteTempReservation");
  }

  getOrgUnitAvailability(orgUnitId: string): Promise<OrgUnitAvailabilityDto> {
    return this.api
      .get<OrgUnitAvailabilityDto>(`/orgUnit/${orgUnitId}/availability`)
      .then(
        response =>
          ({
            orgUnitId,
            orgUnitOpeningHours: response.data.orgUnitOpeningHours,
            orgUnitOpeningHoursOverrides:
              response.data.orgUnitOpeningHoursOverrides
          }) as OrgUnitAvailabilityDto
      );
  }

  updateOrgUnitAvailability(
    request: PatchOrgUnitAvailabilityDto
  ): Promise<OrgUnitAvailabilityDto> {
    return (
      this.api
        // POST is not a mistake. For some obscure reason the
        // API behaves like PATCH but cannot accept PATCH
        .post(`/orgunit/${request.orgUnitId}/availability`, request)
        .then(response => response.data as OrgUnitAvailabilityDto)
    );
  }

  getUserAvailabilities(
    request: UserAvailabilityRequestDto
  ): Promise<UserAvailabilityDto[]> {
    return this.api
      .get<UserAvailabilityDto[]>(`/user/availability?${stringify(request)}`)
      .then(response => response.data as UserAvailabilityDto[]);
  }

  getUserAvailability(
    userId: string,
    orgUnitId: string
  ): Promise<UserAvailabilityDto> {
    return this.api
      .get<UserAvailabilityDto>(`/user/${userId}/availability`)
      .then(
        response =>
          ({
            userId,
            orgUnitId,
            bookingSchedules: response.data.bookingSchedules,
            bookingScheduleOverrides: response.data.bookingScheduleOverrides
          }) as UserAvailabilityDto
      );
  }

  updateUserAvailability(
    request: PatchUserAvailabilityDto
  ): Promise<UserAvailabilityDto> {
    return (
      this.api
        // POST is not a mistake. For some obscure reason the
        // API behaves like PATCH but cannot accept PATCH
        .post(`/user/${request.userId}/availability`, request)
        .then(response => response.data as UserAvailabilityDto)
    );
  }

  getUserUnavailability(
    userId: string,
    orgUnitId: string
  ): Promise<UserUnavailabilityDto> {
    return this.api
      .get<UserUnavailabilityDto>(`/user/${userId}/unavailability`)
      .then(
        response =>
          ({
            userId,
            orgUnitId,
            calendarEventRecurrences: response.data.calendarEventRecurrences
          }) as UserUnavailabilityDto
      );
  }

  updateUserUnavailability(
    request: PatchUserUnavailabilityDto
  ): Promise<UserUnavailabilityDto> {
    return (
      this.api
        // POST is not a mistake. For some obscure reason the
        // API behaves like PATCH but cannot accept PATCH
        .post(`/user/${request.userId}/unavailability`, request)
        .then(response => response.data as UserUnavailabilityDto)
    );
  }

  sendCalendarEventReminder(
    data: SingleCalendarEventReminderDto
  ): Promise<void> {
    const x = this.api.post<void>(
      `/calendarEvents/${data.calendarEventId}/reminder`,
      data
    );
    return x.then(x => x.data);
  }

  getCalendarEventTypes() {
    return this.getRef<CalendarEventTypeRefDto>("calendarEventTypes");
  }

  getOutboundCommStatuses() {
    return this.getRef<CalendarEventTypeRefDto>("outboundCommStatuses");
  }

  getCalendarEventReminderStatuses() {
    return this.getRef<RefDataDto<CalendarEventReminderStatus>>(
      "calendarEventReminderStatuses"
    );
  }

  getCalendarEventStatus() {
    return this.getRef("calendarEventStatuses");
  }

  getCalendarEventPriorities() {
    return this.getRef("calendarEventPriorities");
  }

  getRecurrenceLinkingActionTypes() {
    return this.getRef("recurrenceLinkingActionTypes");
  }

  getAppointmentStatuses() {
    return this.getRef<AppointmentStatusDto>("appointmentStatuses");
  }

  getAppointmentReminderJobStatuses() {
    return this.getRef("appointmentReminderJobStatuses");
  }

  addWaitingListItem(data: AddWaitingListItemDto): Promise<WaitingListItemDto> {
    return this.api
      .post<WaitingListItemDto>("/calendarevents/waitingList", data)
      .then(x => x.data);
  }

  updateWaitingListItem(
    data: UpdateWaitingListItemDto
  ): Promise<WaitingListItemDto> {
    return this.api
      .put<WaitingListItemDto>(`/calendarevents/waitingList/${data.id}`, data)
      .then(x => x.data);
  }

  async deleteWaitingListItem(id: string): Promise<void> {
    return this.api.delete(`/calendarevents/waitingList/${id}`);
  }

  async getAppointmentCancellationReasons(
    params?: GetAppointmentCancellationReasonDto
  ): Promise<AppointmentCancellationReasonDto[]> {
    return this.api
      .get<AppointmentCancellationReasonDto[]>(
        "/appointmentCancellationReasons",
        { params }
      )
      .then(x => x.data);
  }

  async getAppointmentCancellationReason(
    id: string
  ): Promise<AppointmentCancellationReasonDto> {
    return this.api
      .get<AppointmentCancellationReasonDto>(
        `/appointmentCancellationReasons/${id}`
      )
      .then(x => x.data);
  }

  async addAppointmentCancellationReasons(
    request: AddAppointmentCancellationReasonDto
  ): Promise<AppointmentCancellationReasonDto> {
    return this.api
      .post("/appointmentCancellationReasons", request)
      .then(response => response.data as AppointmentCancellationReasonDto);
  }

  async updateAppointmentCancellationReasons(
    data: UpdateAppointmentCancellationReasonDto
  ): Promise<AppointmentCancellationReasonDto> {
    return this.api
      .patch<AppointmentCancellationReasonDto>(
        `/appointmentCancellationReasons/${data.id}`,
        data,
        {
          headers: {
            [IF_MATCH_HEADER]: data.eTag
          }
        }
      )
      .then(x => x.data);
  }

  async deleteAppointmentCancellationReason(id: string): Promise<void> {
    return this.api.delete(`/appointmentCancellationReasons/${id}`);
  }

  private getRef<T extends RefDataDto = RefDataDto>(name: string) {
    return this.api
      .get<T[]>(`/ref/${name}?${withoutFields(excludedRefDataFields)}`)
      .then(x => x.data);
  }

  public async getEventReminders() {
    return this.api
      .get<EventReminderDto[]>("/appointmentReminders", {})
      .then(x => x.data);
  }

  getCalendarEventRemindersBySearchArgs(
    request: CalendarEventReminderSearchArgs
  ): Promise<CalendarEventReminderSearchDto[]> {
    return this.api
      .get<CalendarEventReminderSearchDto[]>(
        `/calendarevents/reminders?${stringify(request)}`
      )
      .then(x => x.data);
  }

  getCalendarEventReminder(
    calendarEventId: string,
    reminderId: string
  ): Promise<CalendarEventReminderDto> {
    return this.api
      .get<CalendarEventReminderDto>(
        `/calendarevents/${calendarEventId}/reminders/${reminderId}`
      )
      .then(x => x.data);
  }

  addCalendarEventReminder(
    calendarEventId: string,
    data: AddCalendarEventReminderDto
  ): Promise<CalendarEventReminderDto> {
    return this.api
      .post(`/calendarevents/${calendarEventId}/reminders`, data)
      .then(response => response.data as CalendarEventReminderDto);
  }

  updateCalendarEventReminder(
    calendarEventId: string,
    reminderId: string,
    data: UpdateCalendarEventReminderDto
  ): Promise<CalendarEventReminderDto> {
    return this.api
      .patch<CalendarEventReminderDto>(
        `/calendarevents/${calendarEventId}/reminders/${reminderId}`,
        data,
        {
          headers: {
            [IF_MATCH_HEADER]: data.eTag
          }
        }
      )
      .then(x => x.data);
  }

  getCalendarEventReminderMessages(
    calendarEventId: string,
    reminderId: string
  ): Promise<CalendarEventReminderMessageDto[]> {
    return this.api
      .get<CalendarEventReminderMessageDto[]>(
        `/calendarevents/${calendarEventId}/reminders/${reminderId}/messages`
      )
      .then(x => x.data);
  }

  getCalendarEventReminderReplies(
    calendarEventId: string,
    reminderId: string
  ): Promise<CalendarEventReminderReplyDto[]> {
    return this.api
      .get<CalendarEventReminderReplyDto[]>(
        `/calendarevents/${calendarEventId}/reminders/${reminderId}/replies`
      )
      .then(x => x.data);
  }

  getAppointmentReminderJobs(): Promise<AppointmentReminderJobDto[]> {
    return this.api
      .get<AppointmentReminderJobDto[]>("/appointmentReminderJobs")
      .then(x => x.data);
  }

  getAppointmentReminderJob(id: string): Promise<AppointmentReminderJobDto> {
    return this.api
      .get<AppointmentReminderJobDto>(`/appointmentReminderJobs/${id}`)
      .then(x => x.data);
  }

  addAppointmentReminderJob(
    data: AddAppointmentReminderJobDto
  ): Promise<AppointmentReminderJobDto> {
    return this.api
      .post("/appointmentReminderJobs", data)
      .then(response => response.data as AppointmentReminderJobDto);
  }

  updateAppointmentReminderJob(
    data: PatchAppointmentReminderJobDto
  ): Promise<AppointmentReminderJobDto> {
    return this.api
      .patch<AppointmentReminderJobDto>(
        `/appointmentReminderJobs/${data.id}`,
        data,
        {
          headers: {
            [IF_MATCH_HEADER]: data.eTag
          }
        }
      )
      .then(x => x.data);
  }

  async deleteAppointmentReminderJob(id: string): Promise<void> {
    return this.api.delete(`/appointmentReminderJobs/${id}`);
  }

  async runAppointmentReminderJob(
    jobId: string,
    args: AppointmentReminderJobRunArgsDto | undefined
  ): Promise<void> {
    return this.api.post(`/appointmentReminderJobs/${jobId}/$run`, args);
  }

  getAppointmentReminderJobPreview(
    request: AppointmentReminderJobPreviewArgs
  ): Promise<AppointmentReminderJobPreviewDto> {
    const defaultScheduleBehaviour =
      AppointmentReminderJobScheduleBehavior.SpanUntilScheduledRunDate;

    const orderedKey = {
      numberOfDaysInAdvanceToSend: request.numberOfDaysInAdvanceToSend,
      scheduleBehaviour: request.scheduleBehaviour ?? defaultScheduleBehaviour,
      selectedDaysOfWeek: request.selectedDaysOfWeek
    };

    const key = stringify(orderedKey);
    return this.api
      .get<AppointmentReminderJobPreviewDto>(
        `/appointmentReminderJobs/preview?${key}`
      )
      .then(x => x.data);
  }

  getAppointmentReminderJobRunSummaries(
    id: string
  ): Promise<AppointmentReminderJobRunSummaryDto[]> {
    return this.api
      .get<AppointmentReminderJobRunSummaryDto[]>(
        `/appointmentReminderJobs/${id}/runSummaries`
      )
      .then(x => x.data);
  }

  getAllAppointmentReminderJobRunSummaries(): Promise<
    AppointmentReminderJobRunSummaryDto[]
  > {
    return this.api
      .get<AppointmentReminderJobRunSummaryDto[]>(
        "/appointmentReminderJobs/runSummaries"
      )
      .then(x => x.data);
  }

  addCalendarEventFormInstance(
    data: CalendarEventFormInstanceDTO
  ): Promise<CalendarEventFormInstanceDTO> {
    return this.api
      .post<CalendarEventFormInstanceDTO>("/calendarEventFormInstance", data)
      .then(x => x.data);
  }

  getAppointmentConfirmationCampaigns(): Promise<
    AppointmentConfirmationCampaignDto[]
  > {
    return this.api
      .get<AppointmentConfirmationCampaignDto[]>(
        "/appointmentConfirmationCampaign"
      )
      .then(x => x.data);
  }

  getAppointmentConfirmationCampaign(
    id: string
  ): Promise<AppointmentConfirmationCampaignDto> {
    return this.api
      .get<AppointmentConfirmationCampaignDto>(
        `/appointmentConfirmationCampaign/${id}`
      )
      .then(x => x.data);
  }

  addAppointmentConfirmationCampaign(
    data: AddAppointmentConfirmationCampaignDto
  ): Promise<AppointmentConfirmationCampaignDto> {
    return this.api
      .post("/appointmentConfirmationCampaign", data)
      .then(response => response.data as AppointmentConfirmationCampaignDto);
  }

  updateAppointmentConfirmationCampaign(
    data: PatchAppointmentConfirmationCampaignDto
  ): Promise<AppointmentConfirmationCampaignDto> {
    return this.api
      .patch<AppointmentConfirmationCampaignDto>(
        `/appointmentConfirmationCampaign/${data.id}`,
        data,
        {
          headers: {
            [IF_MATCH_HEADER]: data.eTag
          }
        }
      )
      .then(x => x.data);
  }

  getCalendarEventConfirmation(
    id: string
  ): Promise<CalendarEventConfirmationDto> {
    return this.api
      .get<CalendarEventConfirmationDto>(`/calendarEventConfirmations/${id}`)
      .then(x => x.data);
  }

  addProviderComment(
    request: AddProviderCommentDto
  ): Promise<CalendarEventDto> {
    const { calendarEventId, ...data } = request;
    return this.api
      .post<CalendarEventDto>(
        `/calendarevents/${calendarEventId}/providerComment`,
        data
      )
      .then(r => r.data);
  }

  getAppointmentEncounters(
    request: GetAppointmentEncountersRequest
  ): Promise<AppointmentEncountersDto[]> {
    return this.api
      .get<AppointmentEncountersDto[]>(
        `/appointmentEncounters?${stringify(request)}`
      )
      .then(x => x.data);
  }

  addAppointmentEncounters(
    data: AddAppointmentEncountersDto
  ): Promise<AppointmentEncountersDto> {
    return this.api
      .post<AppointmentEncountersDto>("/appointmentEncounters", data)
      .then(x => x.data);
  }

  /** APPOINTMENT TYPE **/
  addAppointmentType(data: AddAppointmentTypeDto): Promise<AppointmentTypeDto> {
    return this.api
      .post<AppointmentTypeDto>("/appointmentTypes", data)
      .then(x => x.data);
  }

  updateAppointmentType(
    data: PatchAppointmentTypeDto
  ): Promise<AppointmentTypeDto> {
    return this.api
      .put<AppointmentTypeDto>(`/appointmentTypes/${data.id}`, data)
      .then(x => x.data);
  }

  getAppointmentType(id: string): Promise<AppointmentTypeDto> {
    return this.api
      .get<AppointmentTypeDto>(`/appointmentTypes/${id}`)
      .then(x => x.data);
  }

  async getAppointmentTypes(
    request: GetAppointmentTypesDto
  ): Promise<AppointmentTypeDto[]> {
    const response = await this.api.get<AppointmentTypeDto[]>(
      `appointmentTypes?${stringify(request)}`
    );

    return response.data;
  }

  getTenantSettings(): Promise<BookingTenantSettingsDto> {
    return this.api
      .get<BookingTenantSettingsDto>("/tenants/settings")
      .then(x => x.data);
  }

  addTenantSettings(
    request: AddBookingTenantSettingsDto
  ): Promise<BookingTenantSettingsDto> {
    return this.api
      .post<BookingTenantSettingsDto>("/tenants/settings", request)
      .then(x => x.data);
  }

  updateTenantSettings(
    request: PatchBookingTenantSettingsDto
  ): Promise<BookingTenantSettingsDto> {
    return this.api
      .put<BookingTenantSettingsDto>("/tenants/settings", request)
      .then(x => x.data);
  }
}
