import { ChangeLogDto } from "@libs/api/dtos/index.ts";
import { CorrespondenceType } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

export interface TemplateRenderDto {
  name: string;
  content: string;
  contextData?: string;
}

export interface TemplateRenderOptions {
  context?: { [key: string]: string | boolean };
  contentType?: string;
  parameters?: { [key: string]: string };
  skipMerge?: boolean;
  letterheadId?: string;
  isPreview?: boolean;
  includeDefaultBody?: boolean;
}

interface TemplateParameter {
  templateId?: string;
  name: string;
  dataType: string;
}

export interface TemplateArgs {
  name?: string;
  isLetterhead?: boolean;
}

export interface TemplateDto {
  id: string;
  name: string;
  description?: string;
  templateTypeCode: string;
  content: string;
  templateFormat: string;
  documentType: CorrespondenceType;
  parameters?: TemplateParameter[];
  eTag: string;
  changeLog?: ChangeLogDto;
  isCustom: boolean;
  isSystem: boolean;
  isNew?: boolean;
  isLetterhead: boolean;
  documentStatus?: string;
  isClinical: boolean;
  isAdmin: boolean;
  shortcut?: string;
}

export interface AutofillDto {
  id: string;
  name: string;
  changeLog?: ChangeLogDto;
  isClinical: boolean;
  isAdmin: boolean;
  shortcut: string;
  documentStatus?: string;
}
export interface TemplateDataModelDto {
  nodes: TemplateDataModelNodeDto[];
}

export enum TemplateDataModelNodeType {
  Object = 0,
  Collection = 1,
  String = 2,
  DateTime = 3,
  Integer = 4,
  Decimal = 5,
  Image = 6
}

export interface TemplateDataModelNodeDto {
  children?: TemplateDataModelNodeDto[];
  nodeType?: TemplateDataModelNodeType;
  property?: string;
  text?: string;
}

export interface TemplateDataModelDataDto {
  nodes: TemplateDataModelDataNodeDto[];
}

export interface TemplateDataModelDataNodeDto {
  children?: TemplateDataModelDataNodeDto[];
  property?: string;
  value?: string;
}

export interface TemplateDeleteArgsDto {
  eTag?: string;
  reasonForDelete?: string;
  reasonForDeleteComment?: string;
}

export enum NonCustomTemplateName {
  PracticeLetterhead = "Practice Letterhead",
  ACC45Claim = "ACC45 Claim"
}

export enum TemplateNameDescription {
  investigationReport = "Investigation Report",
  imagingRequest = "Imaging Request",
  prescription = "Prescription"
}
