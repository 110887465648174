import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { IF_MATCH_HEADER } from "@libs/api/api.constants.ts";
import { excludedRefDataFields, RefDataDto } from "@libs/api/ref-data/dto.ts";
import {
  toQueryResult,
  withoutFields,
  withPaging
} from "@libs/api/utils/paging.utils.ts";
import {
  AddIndividualContactDto,
  AddOrganisationContactDto,
  AddPatientDto,
  AddPatientNoticeDto,
  AddProviderDto,
  AddressType,
  ContactDto,
  ContactResultItemDto,
  ContactType,
  GetContactsDto,
  GetMatchedContactsDto,
  GetSasUriResponseDto,
  IndividualContactDto,
  OrganisationContactDto,
  OrgUnitDto,
  PatchIndividualDto,
  PatchOrganisationContactDto,
  PatchPatientDto,
  PatientDto,
  PatientMergeRequest,
  PatientNoticeArgs,
  PatientNoticeDto,
  PatientNoticeTypeDto,
  ProviderAccContractTypeDto,
  ProviderDto,
  ProviderTypeDto,
  PutProviderDto,
  RefContactCategoryDto,
  RefPronounDto,
  RefRelationshipTypeDto,
  SaveProfilePictureDto,
  SaveSignatureDto,
  UpdateMergedStatusRequest,
  UpdateOrgUnitDto
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { IPracticeGateway } from "@libs/gateways/practice/PracticeGateway.interface.ts";
import { nameof } from "@libs/utils/name-of.utils.ts";
import { catchNotFoundError } from "@libs/utils/utils.ts";

const contactExcludedFields = [
  // contactId is always going to be the id of the contact being fetched
  // it is of no use to the front-end
  `${nameof<ContactDto>("relationships")}.contactId`
];

const providerExcludedFields = [nameof<ProviderDto>("changeLog")];

/**
 * Gateway class that is used to communicate with Practice Management backend API
 */
export class PracticeGateway implements IPracticeGateway {
  constructor(private api: AxiosInstance) {}

  /** CONTACT/PATIENT **/
  getSaSUri(): Promise<GetSasUriResponseDto> {
    return this.api
      .get<GetSasUriResponseDto>("contacts/profilePicture/sasUri")
      .then(x => x.data);
  }

  postProfilePicture(request: SaveProfilePictureDto): Promise<ContactDto> {
    return this.api
      .post(`contacts/${request.entityId}/profilePicture`, {
        fileStagingId: request.fileStagingId
      })
      .then(x => ({ ...x.data }));
  }

  deleteProfilePicture(id: string): Promise<void> {
    return this.api.delete(`/contacts/${id}/profilePicture`);
  }

  postOrgUnitProfilePicture(
    request: SaveProfilePictureDto
  ): Promise<OrgUnitDto> {
    return this.api
      .post(`orgUnit/${request.entityId}/profilePicture`, {
        fileStagingId: request.fileStagingId
      })
      .then(x => ({ ...x.data }));
  }

  deleteOrgUnitProfilePicture(id: string): Promise<void> {
    return this.api.delete(`/orgUnit/${id}/profilePicture`);
  }

  addPatient(data: AddPatientDto): Promise<PatientDto> {
    return this.api
      .post<PatientDto>("/patients?", data)
      .then(x => ({ ...x.data }));
  }

  async updatePatient(request: PatchPatientDto): Promise<PatientDto> {
    const { id, eTag, ...data } = request;
    const response = await this.api.patch<PatientDto>(
      `/patients/${id}?${withoutFields(contactExcludedFields)}`,
      data,
      {
        headers: {
          [IF_MATCH_HEADER]: eTag
        }
      }
    );
    return { ...response.data };
  }

  getPatient(id: string): Promise<PatientDto> {
    return this.api
      .get<PatientDto>(
        `/patients/${id}?${withoutFields(contactExcludedFields)}`
      )
      .then(x => ({ ...x.data }));
  }

  addIndividualContact(
    data: AddIndividualContactDto
  ): Promise<IndividualContactDto> {
    return this.api
      .post<IndividualContactDto>(
        `/contacts/individuals?${withoutFields(contactExcludedFields)}`,
        data
      )
      .then(x => ({ ...x.data }));
  }

  async updateIndividual(
    data: PatchIndividualDto
  ): Promise<IndividualContactDto> {
    const { id, eTag, ...rest } = data;
    const response = await this.api.patch<IndividualContactDto>(
      `/contacts/individuals/${id}?${withoutFields(contactExcludedFields)}`,
      rest,
      {
        headers: {
          [IF_MATCH_HEADER]: eTag
        }
      }
    );
    return { ...response.data };
  }

  addOrganisation(
    data: AddOrganisationContactDto
  ): Promise<OrganisationContactDto> {
    return this.api
      .post<OrganisationContactDto>(
        `/contacts/organisations?${withoutFields(contactExcludedFields)}`,
        data
      )
      .then(x => ({ ...x.data }));
  }

  async updateOrganisationContact(
    data: PatchOrganisationContactDto
  ): Promise<OrganisationContactDto> {
    const { id, eTag, ...rest } = data;
    const response = await this.api.patch<OrganisationContactDto>(
      `/contacts/organisations/${id}?${withoutFields(contactExcludedFields)}`,
      rest,
      {
        headers: {
          [IF_MATCH_HEADER]: eTag
        }
      }
    );
    return { ...response.data };
  }

  async getOrgUnit(id: string): Promise<OrgUnitDto | undefined> {
    return this.api
      .get<OrgUnitDto>(`/orgunit/${id}`)
      .then(result => result.data)
      .catch(catchNotFoundError);
  }

  updateOrgUnit(data: UpdateOrgUnitDto): Promise<OrgUnitDto> {
    return this.api
      .put<OrgUnitDto>(`/orgunit/${data.id}`, data)
      .then(x => x.data);
  }

  getContact(id: string): Promise<ContactDto> {
    return this.api
      .get<ContactDto>(`contacts/${id}?${withoutFields(contactExcludedFields)}`)
      .then(x => x.data);
  }

  async getContacts(request: GetContactsDto) {
    let queryString = withPaging(request);
    queryString = withoutFields(contactExcludedFields, queryString);
    const response = await this.api
      .get<ContactResultItemDto[]>(`contacts?${stringify(queryString)}`)
      .then(x => {
        x.data.map<ContactResultItemDto>(contact => Object.assign(contact));
        return x;
      });

    return toQueryResult(response);
  }

  async getMatchedContacts(request: GetMatchedContactsDto) {
    let queryString = withPaging(request);
    queryString = withoutFields(contactExcludedFields, queryString);
    const response = await this.api
      .get<ContactResultItemDto[]>(
        `contacts/matchedContacts?${stringify(queryString)}`
      )
      .then(x => {
        x.data.map<ContactResultItemDto>(contact => Object.assign(contact));
        return x;
      });

    return toQueryResult(response);
  }
  /** PROVIDER **/
  getProvider(id: string): Promise<ProviderDto> {
    return this.api
      .get<ProviderDto>(
        `/provider/${id}?${withoutFields(providerExcludedFields)}`
      )
      .then(x => x.data);
  }

  addProvider(data: AddProviderDto): Promise<ProviderDto> {
    return this.api
      .post<ProviderDto>(
        `/provider?${withoutFields(providerExcludedFields)}`,
        data
      )
      .then(x => x.data);
  }

  putProvider(data: PutProviderDto): Promise<ProviderDto> {
    return this.api
      .put<PutProviderDto>(
        `/provider/${data.id}?${withoutFields(providerExcludedFields)}`,
        data
      )
      .then(x => x.data);
  }

  postSignature(request: SaveSignatureDto): Promise<ProviderDto> {
    return this.api
      .post<ProviderDto>(
        `provider/${request.entityId}/signature?id=${request.entityId}`,
        {
          fileStagingId: request.fileStagingId,
          entityId: request.entityId
        }
      )
      .then(x => ({ ...x.data }));
  }

  deleteSignature(id: string): Promise<void> {
    return this.api.delete(`/provider/${id}/signature?id=${id}`);
  }

  getProviderWithSignatureUrl(id: string): Promise<ProviderDto> {
    return this.api
      .get<ProviderDto>(`/provider/${id}/signature?id=${id}`)
      .then(x => x.data);
  }

  getPatientNotices(id: string) {
    return this.api
      .get<PatientNoticeDto[]>(`patients/${id}/notices`)
      .then(x => x.data);
  }

  getPatientNotice(id: string): Promise<PatientNoticeDto> {
    return this.api
      .get<PatientNoticeDto>(`patients/notices/${id}`)
      .then(x => x.data);
  }

  getPatientNoticesByArgs(
    args: PatientNoticeArgs
  ): Promise<PatientNoticeDto[]> {
    return this.api
      .get<PatientNoticeDto[]>(`patients/notices?${stringify(args)}`)
      .then(x => x.data);
  }

  addPatientNotice(data: AddPatientNoticeDto): Promise<PatientNoticeDto> {
    return this.api
      .post<PatientNoticeDto>(`patients/${data.patientId}/notices`, data)
      .then(x => x.data);
  }

  updatePatientNotice(notice: PatientNoticeDto): Promise<PatientNoticeDto> {
    return this.api
      .put<PatientNoticeDto>(`patients/notices/${notice.id}`, notice)
      .then(x => x.data);
  }

  deletePatientNotice(id: string): Promise<void> {
    return this.api.delete(`patients/notices/${id}`);
  }

  /** REF DATA **/
  getRelationshipTypes() {
    return this.getRef<RefRelationshipTypeDto>("relationshipTypes");
  }

  getContactTypes() {
    return this.getRef<RefDataDto<ContactType>>("contactTypes");
  }

  getAddressTypes() {
    return this.getRef<RefDataDto<AddressType>>("addressTypes");
  }

  getContactStatuses() {
    return this.getRef("contactStatuses");
  }

  getPatientCauseOfDeath() {
    return this.getRef("causesOfDeath");
  }

  getDvaCardColors() {
    return this.getRef("dvaCardColors");
  }

  getContactCategories() {
    return this.getRef<RefContactCategoryDto>("contactCategories");
  }

  getSexes() {
    return this.getRef("sexes");
  }

  getGenders() {
    return this.getRef("genders");
  }

  getPronouns() {
    return this.getRef<RefPronounDto>("pronouns");
  }

  getEthnicities() {
    return this.getRef("ethnicities");
  }

  getLanguages() {
    return this.getRef("languages");
  }

  getOccupations() {
    return this.getRef("occupations", undefined, true);
  }

  getRelationshipStatuses() {
    return this.getRef("relationshipStatuses");
  }

  getAccProviderTypes() {
    return this.getRef<ProviderTypeDto>("accProviderType");
  }

  getEmploymentTypes() {
    return this.getRef("employmentTypes");
  }

  getInvoiceStatuses() {
    return this.getRef("invoiceStatuses");
  }

  getPracticeAccContractTypes() {
    return this.getRef("accPracticeContractType", ["changeLog", "isActive"]);
  }

  getProviderAccContractTypes() {
    return this.getRef<ProviderAccContractTypeDto>("accProviderContractType");
  }

  getRef<T extends RefDataDto = RefDataDto>(
    name: string,
    excludeFields?: string[],
    includeInactive: boolean = false
  ) {
    return this.api
      .get<T[]>(
        `/ref/${name}?$inactive=${includeInactive}&${withoutFields(
          excludeFields || excludedRefDataFields
        )}`
      )
      .then(x => x.data);
  }

  getPatientNoticePriority() {
    return this.getRef<RefDataDto>("patientNoticePriorities");
  }

  getPatientNoticeType() {
    return this.getRef<PatientNoticeTypeDto>(
      "patientNoticeTypes",
      undefined,
      true
    );
  }

  organisationRoleType() {
    return this.getRef<RefDataDto>("organisationRoleType");
  }

  getOrganisations(args: { organisationRoleTypeCodes?: string[] }) {
    return this.api
      .get<ContactDto[]>(`/contacts/organisations?${stringify(args)}`)
      .then(x => x.data);
  }

  getOrgUnitByArgs(args: { orgUnitCompanyDataTypeCodes?: string[] }) {
    return this.api
      .get<OrgUnitDto[]>(`/orgUnit?${stringify(args)}`)
      .then(x => x.data);
  }

  getLinkedAccreditedEmployers(id: string) {
    return this.api
      .get<OrganisationContactDto[]>(
        `/contacts/organisations/${id}/accreditedEmployers`
      )
      .then(x => x.data);
  }

  mergePatients(request: PatientMergeRequest): Promise<void> {
    return this.api
      .post(`/patients/merge?${stringify(request)}`)
      .then(x => x.data);
  }

  updatePatientMergedStatus(
    id: string,
    request: UpdateMergedStatusRequest
  ): Promise<PatientDto> {
    return this.api
      .post(`/patients/${id}/mergedStatus?${stringify(request)}`)
      .then(x => x.data);
  }
}
