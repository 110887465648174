import { stringify } from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { excludedRefDataFields, RefDataDto } from "@libs/api/ref-data/dto.ts";
import {
  toQueryResult,
  withoutFields,
  withPaging
} from "@libs/api/utils/paging.utils.ts";
import {
  AccountBalanceDto,
  AddAllocationDto,
  AddCreditNoteDto,
  AddInvoiceDto,
  AddInvoiceSettingsDto,
  AddPaymentDto,
  AddRefundDto,
  AddScheduleDto,
  AddServiceDto,
  AddStatementDto,
  AddWriteOffDto,
  AllocationDto,
  BillingStoredDocumentDto,
  CancelTransactionRequest,
  CreditNoteDto,
  DocumentUrlDto,
  DraftItemsTotalsDto,
  GetAccountBalanceDto,
  GetBillingStoredDocumentArgs,
  GetInvoiceItemsArgs,
  GetSchedulesArgsDto,
  GetServiceDto,
  GetServiceSearchDto,
  GetSingleBillingStoredDocumentArgs,
  GetStatementArgs,
  GetTransactionsArgs,
  InvoiceDto,
  InvoiceEmailDto,
  InvoiceItemDto,
  InvoiceSettingsDto,
  PaymentDto,
  RefundDto,
  ScheduleDto,
  ServiceDto,
  ServiceSearchDto,
  StatementDto,
  StatementEmailDto,
  TransactionDto,
  TransactionItemDto,
  UpdateInvoiceSettingDto,
  UpdateScheduleDto,
  UpdateServiceDto,
  UpsertInvoiceItemNewRequest,
  WriteOffDto
} from "@libs/gateways/billing/BillingGateway.dtos.ts";
import { IBillingGateway } from "@libs/gateways/billing/BillingGateway.interface.ts";
import { QueryResult } from "@libs/utils/promise-observable/promise-observable.utils.ts";

export class BillingGateway implements IBillingGateway {
  constructor(private api: AxiosInstance) {}

  // Invoice Settings
  getInvoiceSettings(): Promise<InvoiceSettingsDto[]> {
    return this.api
      .get<InvoiceSettingsDto[]>("/invoicesettings")
      .then(x => x.data)
      .then(x =>
        x.map(dto => ({
          // Temporary fix while the API uses both
          ...dto,
          bankAccounts: undefined
        }))
      );
  }

  addInvoiceSetting(data: AddInvoiceSettingsDto): Promise<InvoiceSettingsDto> {
    return this.api
      .post<InvoiceSettingsDto>("/invoicesettings", data)
      .then(x => x.data)
      .then(dto => ({
        // Temporary fix while the API uses both
        ...dto,
        bankAccounts: undefined
      }));
  }

  updateInvoiceSetting(
    data: UpdateInvoiceSettingDto
  ): Promise<InvoiceSettingsDto> {
    return this.api
      .put<InvoiceSettingsDto>(`/invoicesettings/${data.id}`, data)
      .then(x => x.data)
      .then(dto => ({
        // Temporary fix while the API uses both
        ...dto,
        bankAccounts: undefined
      }));
  }

  cancelInvoice(id: string, reason: string): Promise<any> {
    const queryString = { id, reason };
    return this.api.post(`/invoices/cancel/?${stringify(queryString)}`);
  }

  adjustInvoice(
    invoice: AddInvoiceDto,
    id: string,
    reason: string
  ): Promise<InvoiceDto> {
    const queryString = { adjustedTransactionId: id, reason };
    return this.api
      .put(`/invoices/adjust/?${stringify(queryString)}`, invoice)
      .then(x => x.data);
  }

  public async addStatement(data: AddStatementDto): Promise<StatementDto> {
    const result = await this.api.post<StatementDto>("/statement", data);
    return result.data;
  }

  public async getStatements(
    request: GetStatementArgs
  ): Promise<QueryResult<StatementDto>> {
    const queryString = withPaging(request);
    return this.api
      .get<StatementDto[]>(`/statement?${stringify(queryString)}`)
      .then(x => toQueryResult(x));
  }

  getStatement(id: string) {
    return this.api.get<StatementDto>(`/statement/${id}`).then(x => x.data);
  }

  async sendStatementEmail(args: StatementEmailDto): Promise<void> {
    await this.api.post<StatementEmailDto>(
      `/statement/emailStatement?${stringify(args)}`
    );
  }

  deleteStatementById(id: string): Promise<void> {
    return this.api.delete(`/statement/${id}`);
  }

  /** SERVICE **/
  async getServices(request: GetServiceDto): Promise<QueryResult<ServiceDto>> {
    const queryString = withPaging(request);

    const response = await this.api
      .get<ServiceDto[]>(`/services?${stringify(queryString)}`)
      .then(x => {
        x.data = x.data.map(service => Object.assign(service));
        return x;
      });
    return toQueryResult(response);
  }

  getService(id: string) {
    return this.api.get<ServiceDto>(`/services/${id}`).then(x => x.data);
  }

  addService(data: AddServiceDto): Promise<ServiceDto> {
    return this.api.post<ServiceDto>("/services", data).then(x => x.data);
  }

  updateService(data: UpdateServiceDto): Promise<ServiceDto> {
    return this.api
      .put<ServiceDto>(`/services/${data.id}`, data)
      .then(x => x.data);
  }

  /** SCHEDULE **/
  getSchedulesByArgs(request: GetSchedulesArgsDto): Promise<ScheduleDto[]> {
    return this.api
      .get<ScheduleDto[]>(`/schedules?${stringify(request)}`)
      .then(x => x.data);
  }

  getSchedulesByScheduleIds(
    scheduleIds: string[],
    effectiveDate: string
  ): Promise<ScheduleDto[]> {
    return this.api
      .get<ScheduleDto[]>(
        `/schedules/byScheduleIds?${stringify({
          scheduleIds,
          effectiveDate
        })}`
      )
      .then(x => x.data);
  }

  addSchedule(data: AddScheduleDto): Promise<ScheduleDto> {
    return this.api.post<ScheduleDto>("/schedules", data).then(x => x.data);
  }

  updateSchedule(
    data: UpdateScheduleDto,
    effectiveDate: string
  ): Promise<ScheduleDto> {
    return this.api
      .put<ScheduleDto>(
        `/schedules/${data.id}?${stringify({ effectiveDate })}`,
        data
      )
      .then(x => x.data);
  }

  // Ref Data

  getAccScheduleTransactionStatuses() {
    return this.getRef("accScheduleTransactionStatuses");
  }

  getClaimStatuses() {
    return this.getRef("claimStatuses");
  }

  getPaymentMethods() {
    return this.getRef("paymentMethods");
  }

  getPaymentCancellationReasons() {
    return this.getRef("paymentCancelReason");
  }

  getInvoiceCancellationReasons() {
    return this.getRef("invoiceCancelReason");
  }

  getInvoiceAdjustmentReasons() {
    return this.getRef("invoiceAdjustmentReason");
  }

  getPaymentStatuses() {
    return this.getRef("paymentStatuses");
  }

  getBillingStatuses() {
    return this.getRef("billingStatus");
  }

  getItemTypes() {
    return this.getRef("itemTypes");
  }

  // Pdf
  openInvoicePdf(invoiceId: string): Promise<string> {
    const queryString = { id: invoiceId };
    return this.api
      .post(`/invoices/document/?${stringify(queryString)}`)
      .then(x => x.data);
  }

  sendInvoicePdf(invoiceEmails: InvoiceEmailDto[]): Promise<string[]> {
    return this.api
      .post("/invoices/sendEmail/", invoiceEmails)
      .then(x => x.data);
  }

  openAllocationReceiptPdf(allocationId: string): Promise<string> {
    const queryString = { id: allocationId };
    return this.api
      .post(`/allocations/document/?${stringify(queryString)}`)
      .then(x => x.data);
  }

  openPaymentReceiptPdf(paymentId: string): Promise<string> {
    const queryString = { id: paymentId };
    return this.api
      .post(`/payments/document/?${stringify(queryString)}`)
      .then(x => x.data);
  }

  getSasUrl(fileStagingId: string): Promise<string> {
    return this.api
      .get(`/documentRenderResponse/getsasuri/?${stringify({ fileStagingId })}`)
      .then(x => x.data);
  }

  getAccountBalance(request: GetAccountBalanceDto): Promise<AccountBalanceDto> {
    return this.api
      .get<AccountBalanceDto>(`/accountBalance/${request.accountId}`)
      .then(x => x.data);
  }

  getDraftItemsTotals(accountId: string): Promise<DraftItemsTotalsDto> {
    return this.api
      .get<DraftItemsTotalsDto>(`/accountDraftItemTotals/${accountId}`)
      .then(x => x.data);
  }

  getServiceSearch(request: GetServiceSearchDto): Promise<ServiceSearchDto[]> {
    return this.api
      .get<ServiceSearchDto[]>(`/servicesearch?${stringify(request)}`)
      .then(x => x.data);
  }

  private getRef<T extends RefDataDto = RefDataDto>(name: string) {
    return this.api
      .get<T[]>(`/ref/${name}?${withoutFields(excludedRefDataFields)}`)
      .then(x => x.data);
  }

  markInvoiceSent(ids: string[]): Promise<void> {
    const query = stringify({ id: ids });
    return this.api.post(`/invoices/markSent?${query}`);
  }

  sendAllocationEmail(allocationId: string, email?: string): Promise<void> {
    return this.api.post("/allocations/sendEmail/", [
      { transactionId: allocationId, email }
    ]);
  }

  sendPaymentEmail(paymentId: string, email?: string): Promise<void> {
    return this.api.post("/payments/sendEmail/", [
      { transactionId: paymentId, email }
    ]);
  }

  getFeeTypes() {
    return this.getRef("feeTypes");
  }

  async getTransaction<TReturnDto extends TransactionDto<TransactionItemDto>>(
    id: string
  ): Promise<TReturnDto> {
    const result = await this.api.get<TReturnDto>(`/transactions/${id}`);
    return result.data;
  }

  async getTransactionsNew(
    request: GetTransactionsArgs
  ): Promise<QueryResult<TransactionDto<TransactionItemDto>>> {
    const queryString = withPaging(request);
    return this.api
      .get<TransactionDto<TransactionItemDto>[]>(
        `transactions?${stringify(queryString)}`
      )
      .then(x => toQueryResult(x));
  }

  async addWriteOff(data: AddWriteOffDto): Promise<WriteOffDto> {
    const result = await this.api.post<WriteOffDto>("/writeOffs/", data);
    return result.data;
  }

  async generateWriteOffNumber(): Promise<string> {
    const result = await this.api.post<string>("/writeOffs/generatenumber");
    return result.data;
  }

  async cancelWriteOffs(
    request: CancelTransactionRequest
  ): Promise<WriteOffDto> {
    const result = await this.api.post<WriteOffDto>(
      `writeOffs/cancel?${stringify(request)}`
    );
    return result.data;
  }

  async addRefund(data: AddRefundDto): Promise<RefundDto> {
    const result = await this.api.post<RefundDto>("/refunds/", data);
    return result.data;
  }

  async cancelRefund(id: string, reason: string): Promise<RefundDto> {
    const queryString = { id, reason };
    return this.api.post(`/refunds/cancel/?${stringify(queryString)}`);
  }

  async getInvoiceItems(
    req: GetInvoiceItemsArgs
  ): Promise<QueryResult<InvoiceItemDto>> {
    return this.api
      .get<InvoiceItemDto[]>(`/invoiceItems?${stringify(withPaging(req))}`)
      .then(x => toQueryResult(x));
  }

  async upsertInvoiceItems(
    req: UpsertInvoiceItemNewRequest
  ): Promise<InvoiceItemDto[]> {
    const { items: data, ...params } = req;
    const result = await this.api.post<InvoiceItemDto[]>(
      `/invoiceItems?${stringify(withPaging(params))}`,
      data
    );
    return result.data;
  }

  async deleteBulkDraftItems(invoiceItemsIds: string[]): Promise<void> {
    return this.api.delete(
      `/invoiceItems?${stringify({ id: invoiceItemsIds })}`
    );
  }

  async generateRefundNumber(): Promise<string> {
    const result = await this.api.post<string>("/refunds/generatenumber");
    return result.data;
  }

  async addCreditNote(data: AddCreditNoteDto): Promise<CreditNoteDto> {
    const result = await this.api.post<CreditNoteDto>("/creditNotes/", data);
    return result.data;
  }

  async generateCreditNoteNumber(): Promise<string> {
    const result = await this.api.post<string>("/creditNotes/generatenumber");
    return result.data;
  }

  async addAllocation(data: AddAllocationDto): Promise<AllocationDto> {
    const result = await this.api.post<AllocationDto>("/allocations/", data);
    return result.data;
  }

  async generateAllocationNumber(): Promise<string> {
    const result = await this.api.post<string>("/allocations/generatenumber");
    return result.data;
  }

  async cancelAllocation(id: string, reason: string): Promise<any> {
    const queryString = { id, reason };
    return this.api.post(`/allocations/cancel/?${stringify(queryString)}`);
  }

  async addPayment(data: AddPaymentDto): Promise<PaymentDto> {
    const result = await this.api.post<PaymentDto>("/payments/", data);
    return result.data;
  }

  async generatePaymentNewNumber(): Promise<string> {
    const result = await this.api.post<string>("/payments/generatenumber");
    return result.data;
  }

  async cancelPayment(id: string, reason: string): Promise<void> {
    const queryString = { id, reason };
    return this.api.post(`/payments/cancel/?${stringify(queryString)}`);
  }

  async addInvoice(data: AddInvoiceDto): Promise<InvoiceDto> {
    return this.api.post<InvoiceDto>("/invoices", data).then(x => x.data);
  }

  async generateInvoiceNumber(): Promise<string> {
    const result = await this.api.post<string>("/invoices/generatenumber");
    return result.data;
  }

  async getBillingStoredDocumentUrl(
    args: GetBillingStoredDocumentArgs
  ): Promise<DocumentUrlDto> {
    const { contactId, documentId, statementId } = args;
    const result = await this.api.get<DocumentUrlDto>(
      `/billingStoredDocument/documentUrl?${stringify({
        contactId,
        documentId,
        statementId
      })}`
    );
    return result.data;
  }

  async getBillingStoredDocument(
    args: GetSingleBillingStoredDocumentArgs
  ): Promise<BillingStoredDocumentDto> {
    const { contactId, statementId } = args;
    const result = await this.api.get<BillingStoredDocumentDto>(
      `/billingStoredDocument/document?${stringify({
        contactId,
        statementId
      })}`
    );
    return result.data;
  }
}
