import { runInAction } from "mobx";
import { IPromiseBasedObservable, PENDING, REJECTED } from "mobx-utils";

import { PagingOptions } from "@libs/api/dtos/index.ts";
import { MaybePromiseWrapper } from "@libs/utils/promise-observable/MaybePromiseWrapper.tsx";
import {
  IBaseMaybePromiseObservable,
  IPendingPromiseObservable,
  IRejectedPromiseObservable,
  RunQueryOptions
} from "@libs/utils/promise-observable/promise-observable.types.ts";

import { mergePaginationResults } from "../utils.ts";

export interface IMaybePromiseObservable<T>
  extends IBaseMaybePromiseObservable<T> {
  /**
   * The fulfilled value when it wraps a promise that is fulfulled.
   */
  value?: T;

  /**
   * The error if underlying promise is rejected or undefined otherwise.
   * This currently assumes any rejected promise would return an Error.
   */
  error?: Error;
  /**
   * the wrapped promise if any.
   */
  wrappedPromise?: IPromiseBasedObservable<T>;
}

/**
 * Wraps a possible promise in an object with observable properties.
 * This removes some boilerplate code to expose in an reactive manner
 * the state and result of a possible promise.
 *
 * It can also wrap an initial state that corresponds to an absence
 * of promise and therefore cannot be awaited.
 * Instead, await the original promise, the latter is also exposed by the property wrappedPromise.
 *
 * @param promise The promise that should be wrapped in obervable or an undefined initial value.
 */
export const maybePromiseObservable = <T>(
  promise?: PromiseLike<T>
): IMaybePromiseObservable<T> => new MaybePromiseWrapper(promise);
export const isRejected = <T>(
  arg: IMaybePromiseObservable<T>
): arg is IBaseMaybePromiseObservable<T> & IRejectedPromiseObservable<T> => {
  return !!arg.wrappedPromise && arg.wrappedPromise.state === REJECTED;
};
export const isPending = <T>(
  arg: IMaybePromiseObservable<T>
): arg is IBaseMaybePromiseObservable<T> & IPendingPromiseObservable<T> => {
  return !!arg.wrappedPromise && arg.wrappedPromise.state === PENDING;
};

export interface QueryResult<T> {
  skip: number;
  take: number;
  total?: number;
  results: T[];
}

/**
 * Run a given query using the specified action and set the result in the given
 * promise ref.
 * If the fetchNextResults is true, it will call the action with the next
 * paging options and will merge the results with the previous results.
 * contains the updated paging options.
 *
 * Returns the query response.
 * @param params
 * query - The base query to run
 * promiseRef - The promise reference that receives the result
 * action - The action that executes the query. The query parameter contains the updated paging options.
 * options - Paging options and fetchNextResults option.
 */

export const runQuery = <
  TQuery extends object,
  TModel extends { id: string }
>(params: {
  query: TQuery;
  promiseRef: IMaybePromiseObservable<QueryResult<TModel>>;
  action: (query: TQuery & PagingOptions) => Promise<QueryResult<TModel>>;
  options?: RunQueryOptions;
}): Promise<QueryResult<TModel>> => {
  const {
    query,
    promiseRef,
    action,
    options = { fetchNextResults: false }
  } = params;

  const { fetchNextResults, ...paging } = options;
  const newQuery: TQuery & PagingOptions = { ...query, ...paging };
  const lastResult = promiseRef.value;

  if (fetchNextResults && lastResult) {
    newQuery.skip = lastResult ? lastResult.skip + lastResult.take : 0;
    newQuery.take = lastResult.take;
  }

  let queryResultPromise = action(newQuery);

  if (fetchNextResults && lastResult) {
    queryResultPromise = queryResultPromise.then(queryResult => {
      runInAction(() => {
        mergePaginationResults(lastResult, queryResult);
      });
      return lastResult;
    });
  } else {
    promiseRef.set(queryResultPromise);
  }

  return queryResultPromise;
};
