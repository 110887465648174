import { generatePath, matchPath, PathMatch } from "react-router-dom";

import { Permission } from "@libs/gateways/core/CoreGateway.dtos.ts";
import { RoutePermissionOptions } from "@libs/routing/types/route-permission-options.interface.ts";

export enum ClinicalTitles {
  recordView = "Record view",
  recordUpdate = "Record update",
  startConsult = "Consultation",
  documentWriter = "Document writer",
  templateWriter = "Template writer",
  createDocument = "Create document",
  manageTemplate = "Manage template",
  autofillTemplate = "Manage autofills"
}

export enum PeopleTabs {
  Patients = "patients",
  Individuals = "individuals",
  Organisations = "organisations"
}

export enum LaunchFrom {
  Clinical = "clinical",
  Admin = "admin"
}

export const routes = {
  calendarEvents: {
    basePath: createRouteInfo("/appointments", "Booking calendar", {
      permissionsOperator: "and",
      permissions: [
        Permission.CalendarEventRead,
        Permission.BookingScheduleRead
      ]
    }),
    queryKeys: {
      calendarPage: "page",
      reminderTab: "tab"
    }
  },
  addressBook: {
    basePath: createRouteInfo<{ tab: PeopleTabs }>(
      "/address-book/:tab/",
      "Address book",
      {
        permissions: [Permission.ContactRead, Permission.PatientRead]
      }
    )
  },
  contacts: {
    basePath: createRouteInfo("/contacts", "Contact"),
    contact: createRouteInfo<IdParam>("/contacts/:id", "Contact", {
      permissions: [Permission.ContactRead, Permission.PatientRead]
    })
  },
  recentPatients: {
    basePath: createRouteInfo("/recent-patients", "Recent patients", {
      permissionsOperator: "and",
      permissions: [Permission.ContactRead, Permission.PatientRead]
    })
  },
  dashboard: {
    basePath: createRouteInfo("/", "Dashboard")
  },
  documentWriter: {
    basePath: createRouteInfo(
      "/document-writer",
      ClinicalTitles.documentWriter
    ),
    document: createRouteInfo<IdParam>(
      "/document-writer/:id",
      ClinicalTitles.documentWriter,
      {
        permissionsOperator: "or",
        permissions: [
          Permission.PracDocWriterAllowed,
          Permission.EncounterRead,
          Permission.ClinicalRead
        ]
      }
    ),
    template: createRouteInfo<IdParam>(
      "/template-writer/:id",
      ClinicalTitles.templateWriter,
      {
        permissions: [Permission.PracDocWriterAllowed]
      }
    ),
    createDocument: createRouteInfo(
      "/create-document",
      ClinicalTitles.createDocument,
      {
        permissions: [
          Permission.PracDocWriterAllowed,
          Permission.DocumentTemplateRead
        ]
      }
    ),
    manageTemplate: createRouteInfo(
      "/manage-template",
      ClinicalTitles.manageTemplate,
      {
        permissions: [
          Permission.DocumentTemplateWrite,
          Permission.PracDocWriterAllowed
        ]
      }
    ),
    autofillTemplate: createRouteInfo(
      "/autofill-template",
      ClinicalTitles.autofillTemplate,
      {
        permissions: [Permission.AutofillAllowed]
      }
    )
  },
  formDesign: {
    basePath: createRouteInfo("/form-design", "Form design", {
      permissions: [Permission.FormDesignRead, Permission.FormDesignWrite]
    }),
    listView: createRouteInfo("/list", "Online forms")
  },
  templateManagement: {
    basePath: createRouteInfo("/template-management", "Template management", {
      permissions: [
        Permission.TemplateManagementAllowed,
        Permission.TemplateManagementRead
      ]
    }),
    listView: createRouteInfo("/manage", "Template management")
  },
  records: {
    basePath: createRouteInfo("/records"),
    record: createRouteInfo<IdParam>(
      "/records/:id",
      ClinicalTitles.startConsult,
      {
        permissions: [
          Permission.EncounterRead,
          Permission.ClinicalRead,
          Permission.EncounterWrite,
          Permission.CreateConsultAllowed
        ]
      }
    ),
    recordUpdate: createRouteInfo<IdParam>(
      "/records/:id/recordUpdate",
      ClinicalTitles.recordUpdate,
      {
        permissions: [
          Permission.EncounterRead,
          Permission.ClinicalRead,
          Permission.EncounterWrite
        ]
      }
    ),
    recordView: createRouteInfo<IdParam>(
      "/records/:id/view",
      ClinicalTitles.recordView,
      {
        permissions: [Permission.EncounterRead, Permission.ClinicalRead]
      }
    ),
    encounter: createRouteInfo<{ id: string; encounterId: string }>(
      "/records/:id/encounter/:encounterId",
      ClinicalTitles.startConsult,
      {
        permissions: [Permission.EncounterRead, Permission.ClinicalRead]
      }
    ),
    encounterView: createRouteInfo<{ id: string; encounterId: string }>(
      "/records/:id/encounter/:encounterId/view",
      ClinicalTitles.recordView,
      {
        permissions: [Permission.EncounterRead, Permission.ClinicalRead]
      }
    ),
    appointment: createRouteInfo<{
      id: string;
      calendarEventId: string;
    }>(
      "/records/:id/calendarEvent/:calendarEventId",
      ClinicalTitles.startConsult,
      {
        permissions: [Permission.EncounterRead, Permission.ClinicalRead]
      }
    ),
    appointmentView: createRouteInfo<{
      id: string;
      calendarEventId: string;
    }>(
      "/records/:id/calendarEvent/:calendarEventId/view",
      ClinicalTitles.recordView,
      {
        permissions: [Permission.EncounterRead, Permission.ClinicalRead]
      }
    )
  },
  settings: {
    basePath: createRouteInfo("/settings", "Settings"),
    users: {
      basePath: createRouteInfo("/settings/users", "Users settings", {
        permissionsOperator: "or",
        permissions: [
          Permission.SecurityWrite,
          Permission.SecurityRead,
          Permission.UserSettingRead
        ]
      }),
      user: createRouteInfo<IdParam>("/settings/users/:id?", "User settings"),
      userWorkingHours: createRouteInfo<IdParam>(
        "/settings/users/:id/working-hours",
        "User working hours",
        {
          permissionsOperator: "or",
          permissions: [Permission.SecurityRead, Permission.UserSettingRead]
        }
      ),
      userWorkingHoursOverrides: createRouteInfo<IdParam>(
        "/settings/users/:id/exceptions",
        "User exceptions",
        {
          permissionsOperator: "or",
          permissions: [Permission.SecurityRead, Permission.UserSettingRead]
        }
      ),
      reserves: createRouteInfo<IdParam>(
        "/settings/users/:id/reserves",
        "Reserves",
        {
          permissionsOperator: "or",
          permissions: [Permission.SecurityRead, Permission.UserSettingRead]
        }
      ),
      userClinicalView: createRouteInfo<IdParam>(
        "/settings/users/:id/clinical-view",
        "Clinical view",
        {
          permissionsOperator: "or",
          permissions: [
            Permission.SecurityRead,
            Permission.UserSettingRead,
            Permission.ClinSettingAllowed
          ]
        }
      )
    },
    practices: {
      basePath: createRouteInfo("/settings/practice", "Practice settings", {
        permissions: [Permission.OrgUnitSettingWrite]
      }),
      openingHours: createRouteInfo(
        "/settings/practice/opening-hours",
        "Practice opening hours",
        {
          permissions: [Permission.OrgUnitSettingWrite]
        }
      ),
      openingHoursOverrides: createRouteInfo(
        "/settings/practice/exceptions",
        "Practice exceptions",
        {
          permissions: [Permission.OrgUnitSettingWrite]
        }
      ),
      locations: {
        new: createRouteInfo<IdParam>(
          "/settings/practice/location/new",
          "Practice new location"
        ),
        edit: createRouteInfo<IdParam>(
          "/settings/practice/location/edit/:id",
          "Edit practice location"
        )
      }
    },
    accounts: {
      basePath: createRouteInfo("/settings/accounts", "Accounts settings")
    },
    appointmentTypes: {
      basePath: createRouteInfo(
        "/settings/appointment-types",
        "Appointment types",
        {
          permissions: [Permission.AppointmentTypeRead]
        }
      )
    },
    communications: {
      schedules: createRouteInfo(
        "/settings/communications/schedule",
        "Communications schedule settings",
        {
          permissions: [Permission.AppointmentReminderScheduleWrite]
        }
      ),
      templates: createRouteInfo(
        "/settings/communications/templates",
        "Communications templates settings",
        {
          permissions: [
            Permission.CommunicationTemplateRead,
            Permission.CommunicationTemplateWrite
          ],
          permissionsOperator: "or"
        }
      ),
      schedule: {
        new: createRouteInfo<IdParam>(
          "/settings/communications/schedule*/new",
          "New communications schedule",
          {
            permissionsOperator: "and",
            permissions: [Permission.AppointmentReminderScheduleWrite]
          }
        ),
        edit: createRouteInfo<IdParam>(
          "/settings/communications/schedule/edit/:id",
          "Edit communications schedule",
          {
            permissionsOperator: "and",
            permissions: [Permission.AppointmentReminderScheduleWrite]
          }
        )
      },
      confirmationCampaign: {
        new: createRouteInfo(
          "/settings/communications/confirmationCampaign/new",
          "New confirmation campaign",
          {
            permissionsOperator: "and",
            permissions: [Permission.SmsUnderDevelopment]
          }
        ),
        edit: createRouteInfo<IdParam>(
          "/settings/communications/confirmationCampaign/edit/:id",
          "Edit confirmation campaign",
          {
            permissionsOperator: "and",
            permissions: [Permission.SmsUnderDevelopment]
          }
        )
      },
      template: {
        new: createRouteInfo<IdParam>(
          "/settings/communications/template/new",
          "New template",
          {
            permissionsOperator: "and",
            permissions: [Permission.CommunicationTemplateWrite]
          }
        ),
        edit: createRouteInfo<IdParam>(
          "/settings/communications/template/edit/:id",
          "Edit template",
          {
            permissionsOperator: "and",
            permissions: [Permission.CommunicationTemplateWrite]
          }
        )
      }
    },
    schedules: {
      basePath: createRouteInfo("/settings/schedules", "Schedules settings", {
        permissionsOperator: "and",
        permissions: [Permission.ScheduleWrite, Permission.ScheduleRead]
      }),
      viewPath: createRouteInfo<{ scheduleId: string }>(
        "/settings/schedules/:scheduleId",
        "Schedule",
        {
          permissionsOperator: "and",
          permissions: [Permission.ScheduleWrite, Permission.ScheduleRead]
        }
      ),
      feePath: createRouteInfo<{ scheduleId: string; feeId: string }>(
        "/settings/schedules/:scheduleId/fee/:feeId",
        "Fee",
        {
          permissionsOperator: "and",
          permissions: [Permission.ScheduleWrite, Permission.ScheduleRead]
        }
      )
    },
    bhb: {
      basePath: createRouteInfo(
        "/settings/bhb",
        "Best health booking settings"
      ),
      onlineBooking: createRouteInfo(
        "/settings/onlineBooking",
        "Online booking",
        {
          permissions: [Permission.BhbWrite]
        }
      )
    },
    integrations: {
      basePath: createRouteInfo("/settings/integrations", "Integrations", {
        permissionsOperator: "or",
        permissions: [
          Permission.SecurityRead,
          Permission.UserSettingRead,
          Permission.ManageIntegrationsAllowed,
          Permission.IntegrationsAllowed
        ]
      })
    }
  },
  accounts: {
    basePath: createRouteInfo("/accounts", "General accounts", {
      permissionsOperator: "and",
      permissions: [Permission.AccountHistoryAllowed]
    }),
    creditNotes: {
      viewPath: createRouteInfo<IdParam>(
        "/accounts/credit/view/:id",
        "Credit note",
        {
          permissionsOperator: "and",
          permissions: [
            Permission.CreditAllowed,
            Permission.AccountHistoryAllowed
          ]
        }
      ),
      new: createRouteInfo<IdParam>("/accounts/credit/new/:id", "Credit note", {
        permissionsOperator: "and",
        permissions: [
          Permission.CreditAllowed,
          Permission.AccountHistoryAllowed
        ]
      })
    },
    payments: {
      viewPath: createRouteInfo<IdParam>("/accounts/payment/:id", "Payment", {
        permissionsOperator: "and",
        permissions: [Permission.AccountHistoryAllowed]
      })
    },
    invoices: {
      new: createRouteInfo("/accounts/invoice/new", "New invoice", {
        permissionsOperator: "and",
        permissions: [Permission.InvoiceCreate]
      }),
      adjust: createRouteInfo<IdParam>(
        "/accounts/invoice/adjust/:id",
        "Adjust invoice",
        {
          permissionsOperator: "and",
          permissions: [Permission.InvoiceCreate]
        }
      ),
      invoice: createRouteInfo<IdParam>("/accounts/invoice/:id", "Invoice", {
        permissionsOperator: "and",
        permissions: [Permission.AccountHistoryAllowed]
      }),
      queryKeys: { calendarEvent: "calendarEvent" },
      writeOff: {
        viewPath: createRouteInfo<IdParam>(
          "/accounts/invoice/write-off/:id",
          "Write off",
          {
            permissionsOperator: "and",
            permissions: [
              Permission.WriteOffAllowed,
              Permission.AccountHistoryAllowed
            ]
          }
        ),
        new: createRouteInfo<IdParam>(
          "/accounts/invoice/:id/write-off",
          "Write off invoice",
          {
            permissions: [Permission.WriteOffAllowed, Permission.WriteOffCreate]
          }
        )
      }
    },
    allocations: {
      new: createRouteInfo("/accounts/allocation/new", "New allocation", {
        permissionsOperator: "and",
        permissions: [Permission.PaymentCreate]
      }),
      allocation: createRouteInfo<IdParam>(
        "/accounts/allocation/:id",
        "Allocation",
        {
          permissionsOperator: "and",
          permissions: [Permission.AccountHistoryAllowed]
        }
      ),
      queryKeys: { creditNoteId: "creditNoteId", accountId: "accountId" }
    },
    refund: createRouteInfo<IdParam>("/accounts/refund/:id", "Refund", {
      permissionsOperator: "and",
      permissions: [Permission.CreditAllowed, Permission.AccountHistoryAllowed]
    }),
    account: createRouteInfo<IdParam>("/accounts/contact/:id", "Account"),

    queryKeys: {
      invoiceId: "invoiceId",
      accountId: "accountId",
      userId: "userId"
    },

    accInvoices: {
      basePath: createRouteInfo("/accounts/acc/invoices", "ACC billing", {
        permissionsOperator: "and",
        permissions: [
          Permission.PreReleaseAccInvoices,
          Permission.AccountHistoryAllowed,
          Permission.ClaimRead
        ]
      })
    },
    accreditedBilling: {
      basePath: createRouteInfo(
        "/accounts/acc/accredited-billing",
        "Accredited billing",
        {
          permissionsOperator: "and",
          permissions: [
            Permission.PreReleaseAccInvoices,
            Permission.AccountHistoryAllowed,
            Permission.ClaimRead
          ]
        }
      )
    },
    draftItems: {
      basePath: createRouteInfo("/accounts/draft-items", "Draft items", {
        permissionsOperator: "and",
        permissions: [
          Permission.PreReleaseAccInvoices,
          Permission.InvoiceView,
          Permission.ClaimRead,
          Permission.SaveDraftAllowed
        ]
      })
    }
  },
  inbox: {
    upload: createRouteInfo("/inbox/upload", "Upload", {
      permissions: [Permission.UploadDocsAllowed]
    }),
    incoming: createRouteInfo("/inbox/incoming-reports", "Incoming", {
      permissions: [Permission.IncomingReportsAllowed]
    })
  },
  userInbox: {
    basePath: createRouteInfo("/userInbox/", "User inbox")
  },
  tasks: {
    inbox: createRouteInfo("/tasks/inbox", "Follow-up tasks", {
      permissions: [Permission.ContactRead]
    }),
    clinical: createRouteInfo("/tasks/clinical", "Clinical tasks", {
      permissions: [Permission.ContactRead, Permission.ClinTaskRead],
      permissionsOperator: "and"
    })
  },
  conditions: {
    summary: createRouteInfo<IdParam>("/condition/summary/:id", "Condition")
  },
  claims: {
    basePath: createRouteInfo("/claims", "Claims", {
      permissions: [Permission.ClaimRead]
    }),
    edit: createRouteInfo<IdParam>("/claims/edit/:id", "Claims", {
      permissions: [Permission.ClaimRead],
      permissionsOperator: "and"
    }),
    management: {
      edit: createRouteInfo<IdParam>("/claims/summary/edit/:id", "Claims", {
        permissions: [Permission.ClaimRead],
        permissionsOperator: "and"
      })
    }
  },
  claimAdjustment: {
    basePath: createRouteInfo("/claim-adjustment", "Claim adjustment"),
    edit: createRouteInfo<{ id: string; claimId: string }>(
      "/claim-adjustment/edit/:id/claim/:claimId",
      "Edit claim adjustment"
    )
  },
  reports: {
    basePath: createRouteInfo("/reports", "Reports", {
      permissions: [
        Permission.ReportBaseRead,
        Permission.ReportPresetRead,
        Permission.ReportPublishedRead
      ],
      permissionsOperator: "and"
    }),
    view: createRouteInfo<IdParam>("/reports/:id", "Reports", {
      permissions: [
        Permission.ReportBaseRead,
        Permission.ReportPresetRead,
        Permission.ReportPublishedRead
      ]
    })
  }
};

export type IdParam = { id: string };

/** Route Info that exposes the route pattern, and its match and path functions. */
export type RouteInfo<Params extends { [K in keyof Params]?: string } = {}> = {
  /**
   * The route path pattern
   */
  pattern: string;
  /** returns the match result for the path pattern given a pathname */
  match: (pathname: string, partial?: boolean) => PathMatch | null;
  /**
   * generates the path given the route params.
   */
  path: (params: Params) => string;
  title?: string;
} & RoutePermissionOptions;

export const getCreateRouteInfo = (obj: object): RouteInfo<any>[] =>
  Object.values(obj).reduce((acc: RouteInfo<any>[], item) => {
    if (typeof item !== "object") return acc;
    if ("title" in item) {
      return [...acc, item];
    } else {
      const arr = getCreateRouteInfo(item);
      return [...acc, ...arr];
    }
  }, []);

/**
 * Returns a route info that can be used to match against the given pattern or generate its path
 * @param pattern the route pattern. The params structure must match the pattern
 * @param title
 * @param options
 * @example createRouteInfo<{ id: string }>("/users/:id")
 */
function createRouteInfo<Params extends { [K in keyof Params]?: string }>(
  pattern: string,
  title?: string,
  options?: RoutePermissionOptions
): RouteInfo<Params> {
  return {
    pattern,
    title,
    match: (pathName, partial) =>
      matchPath(`${pattern}${!!partial ? "/*" : ""}`, pathName),
    path: params => generatePath(pattern, params),
    ...options
  };
}
